//
// Menu
//

// Aside menu
.aside-menu {
  .menu {
    // Link padding x
    @include menu-link-padding-x(get($aside-config, padding-x));

    // Menu indention
    @include menu-link-indention(get($aside-config, menu-indention), get($aside-config, padding-x));
  }

  // Item
  .menu-item {
    padding: 0;

    // Menu Link
    .menu-link {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    // Icon
    .menu-icon {
      justify-content: flex-start;
    }
  }
}

// Aside dark theme
.aside-dark {
  .hover-scroll-overlay-y {
    --scrollbar-space: 0.4rem;

    @include scrollbar-color(get($aside-config, scrollbar-color),
      get($aside-config, scrollbar-hover-color));
  }

  .menu {
    .menu-item {
      .menu-section {
        color: #4c4e6f !important;
      }

      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state(#1e2ec0, #494b74, #1e2ec0, #1e2ec0, null);
      @include menu-link-hover-state($white, $primary, $primary, $white, #1b1b28);
      @include menu-link-here-state($white, $primary, $primary, $white, #1b1b28);
      @include menu-link-show-state($white, $primary, $primary, $white, #1b1b28);
      @include menu-link-active-state($white, $primary, $primary, $white, #1b1b28);
    }
  }
}

// Aside light theme
.aside-light {
  .menu {
    .menu-title {
      font-weight: 500;
    }
  }
}

.copyright-logos {
  display: flex;
  font-family: 'serif';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: white;
  position: absolute;
  background: #41416e transparent;
  bottom: 0;
  width: 100%;
  height: 3%;
  justify-content: center;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  align-items: center;
  flex-direction: column;
}